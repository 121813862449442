import React, { useState } from "react";
import { salary, time, usa } from "../../../../common/images";

const AccountType = () => {
  const [selected, setSelected] = useState("business");

  const handleSelectCard = (string) => {
    setSelected(string);
  };

  return (
    <>
      <div
        className={`card  ${selected === "personal" ? "active" : ""}`}
        onClick={() => handleSelectCard("personal")}
        style={{ display: "none" }}
      >
        <div className="account-Accounttype-content">
          <div className="Heading">
            <div className="left">
              <h4>Personal Account</h4>
              <ul className="tags list-inline">
                <li>
                  <div className="bg-label-circle"></div>
                  For personal-use
                </li>
              </ul>
            </div>
            <div className="country">
              <img src={usa} alt="flagIcon" />
              <h4>US Based</h4>
            </div>
          </div>
          <div className="Desc">
            <p>Personal for individual use and custom experiences</p>
          </div>
          <ul className="Perks list-inline">
            <li>
              <img src={time} alt="time" />
              Specialty 1
            </li>
            <li>
              <img src={salary} alt="currency" />
              Specialty 2
            </li>
          </ul>
        </div>
      </div>
      <div
        className={`card ${selected === "business" ? "active" : ""}`}
        onClick={() => handleSelectCard("business")}
      >
        <div className="account-type-content">
          <div className="Heading">
            <div className="left">
              <h4>Business Account</h4>
              <ul className="tags list-inline">
                <li>
                  <div className="bg-label-circle"></div>
                  Professional Tools
                </li>
              </ul>
            </div>
            <div className="country">
              <img src={usa} alt="flagIcon" />
              <h4>US Based</h4>
            </div>
          </div>
          <div className="Desc">
            <p>Account opening for Businesses, FinTechs, and Institutions</p>
          </div>
          <ul className="Perks list-inline">
            <li>
              <img src={time} alt="time" />
              24/7 support
            </li>
            <li>
              <img src={salary} alt="currency" />
              Banking
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default AccountType;
