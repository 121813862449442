import React from "react";
import { Controller } from "react-hook-form";
import Select from "react-select";
import { thirdPartyPaymentOption } from "./helper";

const customStyle = (error) => {
  return {
    control: (provided) => ({
      ...provided,
      border: error ? "1px solid #f00" : "1px solid #d0d5dd",
      borderRadius: "8px",
      // height: "44px",
      "&:hover": {
        borderColor: "#1943dc",
      },
    }),
  };
};

const FieldForm = ({
  errors,
  control,
  productServicesList,
  countryList,
  setError,
  setValue,
}) => {
  return (
    <div className="fieldContainer">
      <div
        className={`select-country input ${
          errors?.bConductsThirdPartyPayments && "error-input"
        }`}
      >
        <label htmlFor="legalName">
          Will you be conducting third party payments? (Money movement on behalf
          of others.) <span>*</span>
        </label>

        <Controller
          name="bConductsThirdPartyPayments"
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              options={thirdPartyPaymentOption}
              value={thirdPartyPaymentOption?.states?.find(
                (option) => option.value === field.value
              )}
              onChange={(opt) => {
                setValue("bConductsThirdPartyPayments", opt.value);
                setError("bConductsThirdPartyPayments", null);
                field.onBlur();
              }}
              className="select-dropdown"
              styles={customStyle(
                errors?.bConductsThirdPartyPayments?.message && "error-input"
              )}
              placeholder="Select"
            />
          )}
        />
        {errors.bConductsThirdPartyPayments?.message && (
          <span className="error">
            {errors.bConductsThirdPartyPayments?.message}
          </span>
        )}
      </div>
      <div
        className={`select-country input ${
          errors?.requestedProducts && "error-input"
        }`}
      >
        <label htmlFor="legalName">
          What product or service are you looking to open the account for?
          <span>*</span>
        </label>

        <Controller
          name="requestedProducts"
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              options={productServicesList}
              isMulti
              onChange={(selectedOptions) => field.onChange(selectedOptions)}
              className="select-dropdown"
              styles={customStyle(
                errors?.requestedProducts?.message && "error-input"
              )}
              placeholder="Select"
            />
          )}
        />
        {errors.requestedProducts?.message && (
          <span className="error">{errors.requestedProducts?.message}</span>
        )}
      </div>
      <div
        className={`select-country input ${
          errors?.registeredCountryCode?.message && "error-input"
        }`}
      >
        <label htmlFor="legalName">
          What is the country of the residential address of the individual or
          the country of registration if this is for a business <span>*</span>
        </label>
        <Controller
          name="registeredCountryCode"
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              onChange={(opt) => {
                setValue("registeredCountryCode", opt.value);
                setError("registeredCountryCode", null);
                field.onBlur();
              }}
              value={countryList?.states?.find(
                (option) => option.value === field.value
              )}
              options={countryList}
              className="select-dropdown"
              styles={customStyle(
                errors?.registeredCountryCode?.message && "error-input"
              )}
              placeholder="Select"
            />
          )}
        />
        {errors.registeredCountryCode?.message && (
          <span className="error">{errors.registeredCountryCode?.message}</span>
        )}
      </div>
    </div>
  );
};

export default FieldForm;
